#background{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;  
    min-height: 800px;
}
#about{
  align-items: center;
  justify-content:space-around;
  padding: 200px;
  text-align: center;
  color: aliceblue;
  font-size: large;
  font-weight: 500;

}
nav{
  display: flex;
  justify-content: space-between;
  background-color: #1b2430;
  padding: 20px 80px;
  box-shadow: 0 5px rgba(0,0,0,0.06);
  }
  
  #navbar{
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  #navbar li{
      list-style: none;
      position: relative;
      padding: 0px 20px;
  }
  
  #navbar li a{
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      color: blue;
  }
  
  #navbar li a:hover::after{
  content:"";
  width: 50%;
  height: 2px;
  position: absolute;
  bottom: 0px;
  left: 1px;
  }
  
  #navbar li a:hover{
      color: white;
      transition: 0.3s ease-in-out;
  }